import React from 'react'
import {plansData} from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'
import './Plans.css'

const Plans = () => {
    return (
        <div className="plans-container" id="plans">
            <div className="blur plan-blur-l"></div>
            <div className="blur plan-blur-r"></div>
            <div className="programs-header" style={{gap: '2rem'}}>
                <span className="stroke-text">READY TO START</span>
                <span>YOUR JOURNEY</span>
                <span className="stroke-text">NOW WITHUS</span>
            </div>

            {/* Plans Chart */}
            <div className="plans">
                {
                    plansData.map((plan, inx) => (
                        <div className="plan" key={inx}>
                            {plan.icon}
                            <span>{plan.name}</span>
                            <span>$ {plan.price}</span>

                            <div className="features">
                                {
                                    plan.features.map((feature, idx) => (
                                        <div className="feature" key={idx}>
                                            <img src={whiteTick} alt="" />
                                            <span>{feature}</span>
                                        </div>
                                    ))
                                }
                            </div>

                            <div>
                                <span>See more benefits -></span>
                            </div>
                            <button className="btn">JOIN NOW</button>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Plans
